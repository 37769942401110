

















































































import Base from '@/mixins/Base.vue';

import { IProject, IProjectResponse } from '@/interfaces/project';

const component = Base.extend({
    data() {
        return {

            active: false,
            project: {} as IProject,
        };
    },
    mounted() {
        this.active = true;
    },
    methods: {
        save(): void {
            this.post<IProjectResponse>('projects', {
                name: this.project.name,
                client_id: this.project.client.id,
            }).then(({ data }) => {
                this.$router.push({ name: 'projects.edit', params: { id: data.data.id.toString() } });
            });
        },
    },
});

export default component;
