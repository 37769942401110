var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{attrs:{"to":"modal"}},[_c('b-modal',{attrs:{"active":_vm.active,"can-cancel":false}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var valid = ref.valid;
return [_c('form',{staticClass:"modal-card",on:{"submit":function($event){$event.preventDefault();return passes(_vm.save)}}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" Create a new project ")])]),_c('section',{staticClass:"modal-card-body"},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"name","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Name","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.project.name),callback:function ($$v) {_vm.$set(_vm.project, "name", $$v)},expression:"project.name"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"client_id","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Client","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-client',{attrs:{"append-to-body":"","disabled":_vm.isLoading},model:{value:(_vm.project.client),callback:function ($$v) {_vm.$set(_vm.project, "client", $$v)},expression:"project.client"}})],1)]}}],null,true)})],1),_c('footer',{staticClass:"modal-card-foot"},[_c('sp-link',{attrs:{"tag":"b-button","disabled":_vm.isLoading,"to":{name: 'projects.list'},"label":_vm.$t('close'),"icon-left":"times"}}),_c('b-button',{attrs:{"disabled":! valid || _vm.isLoading,"label":_vm.$t('create'),"icon-left":"save","type":"is-primary","native-type":"submit"}})],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }